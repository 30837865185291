import React, { useEffect, useState, useRef } from "react"
import Layout from "../layout"
import SEO from "../seo"
import Paginator from "../paginator"
import fetch from "node-fetch"
import moment from "moment"
import fileDownload from "js-file-download"
import { backendHost } from "../../config"

const KeyValue = props => (
  <div className="pa2 flex flex-row-ns flex-column">
    <div className="w-30-ns w-100">
      <h3 style={{ fontSize: "16px", margin: "0", padding: "0" }}>
        {props.keyName}
      </h3>
    </div>
    <div className="w-70-ns w-100">
      <span className="uk-text-justify uk-inline">{props.value}</span>
    </div>
  </div>
)

let sizesBuffer = []

export default function ImageGalleryPage({ pageContext }) {
  const data = pageContext
  const [imageSizes, setImageSizes] = useState([])
  const mediaDownloadRef = useRef(null)

  const downloadFile = (url, title) => {
    fetch(url)
      .then(response => response.blob())
      .then(response => {
        const ext = url.split(".")
        fileDownload(
          response,
          title.split(" ").join("-") + "." + ext[ext.length - 1]
        )
      })
  }

  useEffect(() => {
    data.content.map((image, index) => {
      const fetchImage = fetch(image.media.publicURL).then(res => {
        let allImages = [...imageSizes]
        allImages[index] = Math.floor(
          res.headers.get("Content-Length") / 1024 / 1024
        )
        setImageSizes(allImages)
      })
    })
  }, [])

  return (
    <Layout lang="ar">
      <SEO title="صور التقطها مسبار الأمل" />
      <main>
        <div className="standard-container" dir="rtl">
          <h2 className="relative uk-text-center mt6 mb5">
            صور التقطها مسبار الأمل
            <div
              className="text-underline-heavy"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            ></div>
          </h2>
          {/* <p className="mv5 f5">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin rhoncus lacinia. Aenean eu varius ex, vel placerat augue. Quisque non luctus diam. Vestibulum metus est, eleifend a cursus a, ornare at nibh. In rutrum scelerisque metus, at convallis augue rutrum ut. Sed vehicula accumsan mi eget consectetur.
                    </p> */}
          <div id="gallery-lightbox">
            {data.content.map((image, index) => {
              const singleMedia = image.media.length <= 1
              return (
                <div className="flex image-card flex-column flex-row-ns items-center items-start-ns justify-center mb5">
                  <div
                    className="ml3-ns mb3 mb0-ns"
                    style={{
                      display: "inline-block",
                      position: "relative",
                      maxWidth: "300px",
                    }}
                  >
                    <div
                      {...(!singleMedia && {
                        "data-uk-slider": "center: true",
                      })}
                    >
                      <div className="uk-position-relative uk-dark">
                        <ul
                          className="uk-slider-items uk-child-width-1-1"
                          data-uk-lightbox="animation: slide"
                        >
                          {image.media.map(_image => (
                            <a
                              href={_image.formats.medium.publicURL}
                              {...(!singleMedia && {
                                "data-uk-slider-item": true,
                              })}
                            >
                              <img src={_image.formats.medium.publicURL} />
                            </a>
                          ))}
                        </ul>
                        <a
                          className="uk-position-center-left uk-position-small uk-hidden-hover"
                          uk-slidenav-previous
                          uk-slider-item="previous"
                        >
                          <span
                            className="icon-white"
                            uk-icon="icon: chevron-left; ratio: 2"
                          />
                        </a>
                        <a
                          className="uk-position-center-right uk-position-small uk-hidden-hover"
                          uk-slidenav-next
                          uk-slider-item="next"
                        >
                          <span
                            className="icon-white"
                            uk-icon="icon: chevron-right; ratio: 2"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 items-center">
                    <KeyValue
                      keyName="العنوان"
                      value={image.arabic_title}
                    ></KeyValue>
                    <KeyValue
                      keyName="التاريخ"
                      value={
                        moment(image.date)
                          .format("MMMM Do YYYY, h:mm:ss a")
                          .toString()
                          .split(",")[0]
                      }
                    />
                    <KeyValue
                      keyName="الأجهزة العلمية"
                      value={image.arabic_instruments}
                    ></KeyValue>
                    <KeyValue
                      keyName="التفاصيل"
                      value={image.arabic_description}
                    ></KeyValue>
                    <KeyValue
                      keyName="تحميل الصورة"
                      value={
                        <>
                          <span
                            className="span-link"
                            onClick={e =>
                              downloadFile(
                                `${backendHost}/whpis/whpis-${
                                  image.strapiId || image.id
                                }.zip`,
                                image.title
                              )
                            }
                            style={{
                              cursor: "pointer",
                              color: "#fff",
                              fontFamily: "martianbthai",
                              textDecoration: "underline",
                            }}
                          >
                            تحميل (جودة عالية)
                          </span>
                          &nbsp;
                          {Math.round(
                            image.media.reduce(
                              (total, c) => total + c.size,
                              0
                            ) / 1024
                          )}{" "}
                          MB
                          {/* <span className="span-link" onClick={e => downloadFile(image.media.publicURL)} style={{ cursor: 'pointer', color: '#01AEBC' }}>تحميل (جودة عالية)</span> {imageSizes[index]} MB */}
                        </>
                      }
                    ></KeyValue>
                  </div>
                </div>
              )
            })}
          </div>
          <div
            style={{
              marginTop: "60px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Paginator
              totalPages={data.totalPages}
              pageNumber={data.pageNumber}
              prefix={"/gallery/images-of-hope-probe/"}
            />
          </div>
        </div>
        <div>
          <a ref={mediaDownloadRef} download="download.png" />
        </div>
      </main>
    </Layout>
  )
}
